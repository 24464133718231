.item-enter {
  opacity: 0;
  transform: translateY(-200px);
}
.item-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms, transform 200ms;
}
.item-exit {
  opacity: 1;
  transform: translateY(0);
}
.item-exit-active {
  opacity: 0;
  transform: translateY(-200px);
  transition: opacity 200ms, transform 200ms;
}
