@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --error-color: #e74c3c;

  --Legend-Actual: #B18C22;

  --Brand-primary-1: #15332B;
  --Brand-green-primary-1: #639160;
  --Brand-pink-1: #FC5A9F;
  --Text-Secondary: #757575;

  --white: #FFFFFF;
  --black: #000000;
  --brand-red-primary: #F20505;
  --brand-red-secondary: #790202;
  --brand-red-tertiary: #E88B93;
  --brand-5th-primary: #0597F2;
  --brand-5th-secondary: #185A8A;
  --brand-5th-tertiary: #A7D6E8;
  --brand-yellow-primary: #F5D410;
  --brand-yellow-secondary: #A8841D;
  --brand-yellow-tertiary: #F2F06C;
  --brand-4th-primary: #FC5A9F;
  --brand-4th-secondary: #831846;
  --brand-4th-tertiary: #CD93DB;
  --brand-green-primary: #14AE5C;
  --brand-green-secondary: #256141;
  --brand-green-tertiary: #80F289;
  --dark-1: #171717;
  --dark-2: #262626;
  --dark-3: #373737;
  --dark-4: #525252;
  --dark-5: #8A8A8A;
  --light-1: #E5E5E5;
  --light-2: #D4D4D4;
  --light-3: #A3A3A3;
  --light-4: #8A8A8A;
  --light-5: #525252;
}
