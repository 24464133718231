.StageWraper {
  padding: 0 12px;
  min-width: 550px;
  width: calc((100%) / 3); /* 1/3 of the screen width minus the sidebar */
  display: inline-flex;
  flex-direction: column;

  &:not(.lastStage) {
    .stageContent {
      //padding-right: 24px;
    }

    .stageLine {
      &:after {
        content: '';
        position: absolute;
        top: 45%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: rgba(196, 175, 175, 0.5);
        z-index: 0;
      }
    }
  }

  .stageLine {
    position: relative;

    .stageButton {
      z-index: 10;
    }

    &.active {
      .stageButton {
        background: var(--Brand-pink-1);
      }

      &:after {
        background-color: var(--Brand-pink-1);
      }
    }

  }

}
.stageLine {
  &:after {
    content: '';
    position: absolute;
    top: 45%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgba(196, 175, 175, 0.5);
    z-index: 0;
  }
}

.stageLineChartWraper {
  background: var(--dark-2);
}
