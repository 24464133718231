.steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 0 auto;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #ccc;
    z-index: 0; // Behind the steps
    transform: translateY(-50%);
  }

  .step {
    position: relative;
    z-index: 1; // In front of the line
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #333;
    transition: all 0.3s ease;
    .stageButton {
      background: var(--Text-Secondary);
    }

    &.active {
      &.active {
        .stageButton {
          background: var(--brand-5th-primary);
        }

        &:after {
          background-color: var(--brand-5th-primary);
        }
      }
    }
  }
}
